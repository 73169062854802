import React from 'react';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Press from '../components/press';


export default function PressPage({ location, data }) {

  return (
    <Layout location={location}>
      <Press data={data} />
    </Layout>
  )
}

export const fetchAllNewsQuery = graphql`
  {
    allNews {
  
        newsFeeds(sort: "published_date:desc") {
            id
            headline
            short_description
            url
            logo_url
            published_date
          }
        }
  }
`;