import React, { useEffect } from 'react';
import { scrollAnimation } from "../lib/utils"
import PressHero from "./press_hero"
import moment from 'moment'

export default function Press({ data }) {

    const isEvenCount = data.allNews.newsFeeds.length % 2 == 0;

    useEffect(() => {
        scrollAnimation()

    }, [])

    return (
        <>
            {/* <div className="absolute top-0  sm:pt-12 h-100v w-full bg-gradient-to-tr from-primary-blue  to-sky-900 md:pb-32 z-0">
            </div>
            <div className="relative flex flex-col sm:flex-row sm:h-50v lg:h-60v xl:h-40v items-center justify-between md:justify-evenly mt-32 p-2 sm:mt-28  ">
                <div className="  rounded-3xl  w-3/5 md:w-full h-full  flex flex-col items-center justify-center ">
                    <h2 className=" font-serif inline text-4xl text-sky-50  font-bold tracking-wide sm:text-6xl lg:text-6xl xl:text-8xl">Press </h2>
                </div>
                <div className=" bg-sky-50  rounded-3xl sm:w-full h-full flex flex-col items-center justify-center text-center px-16 ">
                    <div className=" text-xl lg:text-2xl h-70% sm:40% lg:h-70% xl:90% w-full  sm:bg-sky-50 flex flex-col items-center justify-center p-2 lg:p-2 ">
                        <p className="font-serif font-bold text-bright-orange ">{data.allNews.newsFeeds[0].headline}</p>
                        <p className="mt-3 text-base text-gray-500 lg:text-lg">{data.allNews.newsFeeds[0].short_description}</p>
                    </div>
                </div>
            </div>
            <div className=" relative sm:h-4 bg-sky-50 w-full">
            </div> */}

            <PressHero pressData={data} />

            <div className=" relative mt-56 sm:mt-48 md:mt-52 lg:mt-52">
                <div class=" relative grid grid-cols-1 md:grid-cols-2 gap-small  md:px-2">
                    {data.allNews.newsFeeds.slice(1).map((post) => (
                        <div className="relative h-80 sm:h-52 md:h-64 lg:h-52 xl:h-44 w-90v md:w-full  flex flex-col   border-l-2   border-orange-primary m-8 px-8">

                            <p className="font-heading2">{post.headline}</p>
                            {/* <p className="mt-3 text-base text-gray-500">{post.short_description}</p> */}
                            <div className="flex  justify-between items-center w-full ">
                                <div>
                                    <p className="text-base pt-10 ">
                                        <time dateTime={post.datetime}> {moment(post.published_date).format("MMM DD YYYY")}</time>
                                    </p>
                                    <a href={post.url} target="_blank" className="text-base font-semibold text-orange-primary hover:text-orange-700">
                                        Read full story
                                    </a>
                                </div>
                                <div className=" bg-sky-50 pt-10">
                                    <a href={post.url} target="_blank" className="  ">

                                      <img class=" object-contain h-20 w-20   " src={post.logo_url} alt="" />
                                    </a>
                                </div>
                            </div>


                        </div>
                    ))}
                    {isEvenCount ? (
                        <div className="h-64 md:h-56 w-full bg-sky-50 flex flex-col items-center justify-center text-center p-16 ">  </div>
                    ) : (
                        <></>
                    )}

                </div>
            </div>


        </>
    )
}