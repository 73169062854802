import React from 'react';
import moment from 'moment'

export default function PressHero(data) {
    return (
        <section className="relative ">
            <div className="h-50v md:h-30v lg:h-20v ls:h-70v bg-primary-blue"></div>
            <div>
                <svg viewBox="0 0 1440 285" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1440 -2V34.2881V34.2881C1426.13 110.841 1362.67 168.598 1285.15 175.22L0 285V-2H1440Z" fill="#0F4D92" />
                </svg>
            </div>
            <div className="absolute top-25% inset-x-0">
                <div className="flex flex-col justify-center items-center px-2 md:px-15% content-center ">
                    <h1 className="font-banner-heading "> <span >Press</span> </h1>
                    
                    <div className=" bg-sky-50 rounded-3xl  shadow-2xl  py-14  border-2 border-orange-primary      mt-32 sm:mt-36 md:mt-32 text-2xl sm:text-3xl max-w-3xl text-center font-light tracking-wider   text-gray-900 px-2 md:px-10">
                        
                            <p className="font-heading2">{data.pressData.allNews.newsFeeds[0].headline}</p>
                            {/* <p className="mt-3 text-base text-gray-500 lg:text-lg">{data.pressData.allNews.newsFeeds[0].short_description}</p>        */}
                            <div className="flex justify-between mx-4">  
                                <div className="text-base pt-10 flex flex-col  ">
                                    <time dateTime={data.pressData.allNews.newsFeeds[0].datetime}> {moment(data.pressData.allNews.newsFeeds[0].published_date).format("MMM DD YYYY")}</time>
                                    <a href={data.pressData.allNews.newsFeeds[0].url} target="_blank" className="text-base font-semibold text-orange-primary hover:text-orange-700 ">
                                        Read full story
                                    </a>
                                </div>
                                <div className="mt-4">
                                    <a href={data.pressData.allNews.newsFeeds[0].url} target="_blank" className="  ">
                                        <img class=" object-contain h-20 w-20   " src={data.pressData.allNews.newsFeeds[0].logo_url} alt="" />
                                    </a>
                                </div>
                            </div>
                        
                    </div>
                </div>
  
            </div>
                <div className="invisible md:visible absolute top-32 left-4 h-20v w-20v" aria-hidden="true">
                <svg
                    className="relative    "
                    fill="none"
                    viewBox="0 0 404 150"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-indigo-500" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={400} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                </svg>

            </div>
        </section>

    )
}
